<template>
  <div>
    <el-dialog
      top="12%"
      :close-on-click-modal="false"
      title="新增热门"
      :visible.sync="dialogVisible"
      width="444px"
      :modal-append-to-body="false"
      class="pop-dialog"
    >
      <el-form
        ref="addNewForm"
        :rules="rules"
        :model="addNewForm"
        label-width="80px"
        label-position="left"
      >
        <el-form-item label="名称" prop="keyword">
          <el-input v-model="addNewForm.keyword" class="dialog-input" placeholder="请输入热搜词" />
        </el-form-item>

        <el-form-item label="热搜量" prop="search_count">
          <el-input
            v-model="addNewForm.search_count"
            class="dialog-input"
            placeholder="热搜量高者优先"
            autocomplete="off"
          />
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          class="confirm-button"
          @click="addNewSearch"
          size="medium"
          >确认</el-button
        >
        <el-button @click="hide" class="cancel-button">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import searchApi from "@/api/search";
import formValidation from "@/mixins/form-validations";

export default {
  name: "AddNewDialog",
  mixins: [formValidation],


  data() {
    return {
      addNewForm: {
        keyword: "",
        search_count: "",
      },
      dialogVisible: false,
      market: "US",
    };
  },

  methods: {
    clear() {
      this.$nextTick(() => {
        this.$refs.addNewForm.resetFields();
      });
    },
    addNewSearch() {
      const newSearchData = {
        keyword: this.addNewForm.keyword,
        search_count: this.addNewForm.search_count,
        market: this.market,
      };
      this.$refs.addNewForm.validate((valid) => {
        if (valid) {
          searchApi.addNewSearch(newSearchData).then((res) => {
            if (res.data.code === 10200) {
              this.hide();
              this.updateList();
              this.$$success('成功添加热搜词！')
            } else {
              this.$$error(res.data.message);
            }
          });
        }
      });
    },

    show() {
      this.dialogVisible = true;
      this.clear();
    },

    hide() {
      this.dialogVisible = false;
      this.clear();
    },

    updateList() {
        this.$emit('update')
    }
  },
};
</script>