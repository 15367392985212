import axios from '../uitls/require'

export default {
    getSearchList(marketInfo) {
        return axios.post('/admin/search/list', marketInfo)
    },
    addNewSearch(newSearchInfo) {
        return axios.post('/admin/search/add', newSearchInfo)
    },
    editSearch(searchInfo) {
        return axios.post('/admin/search/update', searchInfo)
    },
    deleteSearch(id) {
        return axios.get('/admin/search/delete?id=' + id)
    },
    getSearchItem(id) {
        return axios.get('/admin/search?id='+ id)
    }
}