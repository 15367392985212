<template>
  <div>
    <el-dialog
      top="12%"
      :close-on-click-modal="false"
      title="编辑"
      :visible.sync="dialogVisible"
      width="444px"
      :modal-append-to-body="false"
      class="pop-dialog"
    >
      <el-form
        ref="editForm"
        :rules="rules"
        :model="editForm"
        label-width="80px"
        label-position="left"
      >
        <el-form-item label="名称" prop="keyword">
          <el-input v-model="editForm.keyword" class="dialog-input" />
        </el-form-item>

        <el-form-item label="热搜量" prop="search_count">
          <el-input
            v-model="editForm.search_count"
            class="dialog-input"
            placeholder="热搜量高者优先"
            autocomplete="off"
          />
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" class="confirm-button" @click="editSearch" size="medium">确认</el-button>
        <el-button @click="hide" class="cancel-button">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import searchApi from "@/api/search";
import formValidation from "@/mixins/form-validations";

export default {
  name: "EditDialog",
  mixins: [formValidation],

  data() {
    return {
      editForm: {
        id: "",
        keyword: "",
        search_count: "",
      },
      dialogVisible: false,
      market: "US",
    };
  },

  methods: {
    clear() {
      this.$nextTick(() => {
        this.$refs.editForm.resetFields();
      });
    },
    editSearch() {
      const searchData = {
        id: this.editForm.id,
        keyword: this.editForm.keyword,
        search_count: this.editForm.search_count,
        market: this.market,
      };

      this.$refs.editForm.validate((valid) => {
        if (valid) {
          searchApi.editSearch(searchData).then((res) => {
            if (res.data.code === 10200) {
              this.hide();
              this.updateList();
            } else {
              this.$$error(res.data.message);
            }
          });
        }
      });
    },
    updateList() {
      this.$emit("update");
    },

    show(row) {
      row.search_count = row.search_count.toString();
      this.dialogVisible = true;
      this.editForm = { ...row };
    },

    hide() {
      this.dialogVisible = false;
      this.clear();
    },
  },
};
</script>