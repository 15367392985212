<template>
  <div v-if="hasResource('popular_search_management')">
    <!-- 弹窗 -->
    <add-new-dialog ref="addNewPop" @update="init"></add-new-dialog>
    <edit-dialog ref="editPop" @update="init"></edit-dialog>
    <delete-dialog ref="deletePop" @update="init"></delete-dialog>

    <el-row class="mg-t-72 mg-b-20">
      <el-col :span="3">
        <el-button
          class="black-btn btn-l"
          v-if="hasResource('popular_search_add')"
          @click="handleAddNew"
        >新增热门</el-button>
      </el-col>
    </el-row>
    <div class="search-notice mg-t-30">
      <span>最多支持展示搜索量前十的热搜词</span>
    </div>

    <!-- 热搜表格 -->
    <div class="search-list-table mg-t-20">
      <el-table
        :data="listData"
        style="width: 100%"
        class="data-table"
        header-cell-class-name="table-head"
        cell-class-name="table-cell"
        v-loading="tableLoading"
      >
        <el-table-column
          label="搜索词"
          prop="keyword"
          min-width="12%"
          width="280px"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          label="热搜量"
          prop="search_count"
          min-width="12%"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column label="来源" prop="source" min-width="12%" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span>{{scope.row.source===0?'用户搜索':'管理员创建'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="50%" :show-overflow-tooltip="true">
          <template slot="header">
            <div style="padding-left:22px !important;">操作</div>
          </template>
          <!-- 条目操作 -->
          <template slot-scope="scope">
            <div class="icon-btn">
              <el-button
                size="mini"
                type="text"
                v-if="hasResource('popular_search_edit')&&scope.row.source===1"
                @click="handleEdit(scope.$index, scope.row)"
              >
                <i class="iconfont">&#xe677;</i>编辑
              </el-button>
              <el-button
                size="mini"
                type="text"
                v-if="hasResource('popular_search_delete')"
                @click="handleDelete(scope.$index, scope.row)"
              >
                <i class="iconfont">&#xe678;</i>删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <p class="mg-t-20">共{{ listInfo.total }}条</p>
  </div>
</template>
<script>
import search from "@/mixins/form-validations";
import searchApi from "@/api/search";
import { addNewDialog, editDialog, deleteDialog } from "./dialogs/dialog-index";
// import Info from "../../asset-management/allAssets/info.vue";

export default {
  components: { addNewDialog, editDialog, deleteDialog },
  mixins: [search],
  data() {
    return {
      tableLoading: false,
      listInfo: {},
      listData: [],
      market: "US",
    };
  },

  methods: {
    init() {
      this.searchListInfo();
    },

    handleAddNew() {
      this.$nextTick(() => {
        this.$refs.addNewPop.show();
      });
    },

    handleEdit(index, row) {
      this.$nextTick(() => {
        this.$refs.editPop.show(row);
      });
    },
    handleDelete(index, row) {
      this.$nextTick(() => {
        this.$refs.deletePop.show(row);
      });
    },

    searchListInfo() {
      this.tableLoading = true;
      const marketInfo = { market: this.market };

      searchApi.getSearchList(marketInfo).then((res) => {
        if (res.data.code === 10200) {
          this.listInfo = res.data.data;
          this.listData = res.data.data.items;
          this.tableLoading = false;
        }
      });
    },
  },

  mounted() {
    this.init();
  },
};
</script>
<style lang="less">
@import "./search-list.less";
</style>