<template>
    <div>
        <el-dialog top="12%"  :close-on-click-modal="false" :visible.sync="dialogVisible" width="458px"  :modal-append-to-body='false' class="pop-dialog">
            <div class="del-title">
                <div class="del-left"><h4> 您将删除热门搜索词</h4></div>
                <div class="mg-l-10 del-right"><h3>{{searchItem.keyword}}</h3></div>
            </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" class="confirm-button" @click="deleteSearch" size="medium">确认</el-button>
          <el-button @click="hide" class="cancel-button">取 消</el-button>
        </span>
      </el-dialog>

    </div>
</template>
<script>
import searchApi from '@/api/search';
import search from '@/mixins/form-validations';

export default {
    name:'DeleteDialog',
    mixins: [search],

    data() {
        return {
            dialogVisible: false,
            searchItem:{}
        }
    },

    methods: {
        deleteSearch() {
            searchApi.deleteSearch(this.searchItem.id).then(res => {
                if (res.data.code === 10200) {
                    this.hide()
                    this.updateList()
                } else {
                    this.$$error(res.data.message)
                }
            })
        },

        updateList() {
            this.$emit('update')
        },

        show(row) {
            this.dialogVisible = true
            this.searchItem = {...row}
        },

        hide() {
            this.dialogVisible = false
        }

    },

}
</script>

<style lang="less" scoped>
h4 {
    font-size: 18px;
}
h3 {
    font-size: 24px;
    font-weight: 700;
    
}
.del-title {
    display: flex;
    padding: 0 0 0 8px;
    .del-right {
        width: 35%;
        h3 {
            overflow: hidden;
            line-height: 30px;
            margin-top: -6px;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}


</style>